/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UpgradeProductFps } from '@framework/api/types';
import type { State } from './types';

export const getFps = (state: State): UpgradeProductFps => state.fps;

export const getSetFps = (state: State): ((fps: UpgradeProductFps) => void) =>
  state.setFps;

export const getClearFps = (state: State): (() => void) => state.clearFps;
