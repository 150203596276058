/* eslint-disable @typescript-eslint/no-explicit-any */
import create from 'zustand';
import type { SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { UpgradeProductFps } from '@framework/api/types';
import type { State } from './types';

const initialFpsState = {
  fps: null,
  setFps: () => null,
  clearFps: () => null,
};

const uiStore = (set: SetState<State>): State => ({
  ...initialFpsState,
  setFps: (fps: UpgradeProductFps) => set(() => ({ fps })),
  clearFps: () => set(() => ({ fps: null })),
});

const useUiStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(uiStore))
    : create<State>(uiStore);

export default useUiStore;
