import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { DEFAULT_LOCALE, DEFAULT_LANGUAGE_CODE } from '@constants';
import parseLocale from '@utils/parse-locale';
import CardModal from './CardModal';

import * as styles from './styles';

type Props = {
  heading?: string;
  subheading?: string;
  withToolTip?: boolean;
  toolTipContent?: string;
  className?: string;
};

const CardHeading: FC<Props> = ({
  heading,
  subheading,
  withToolTip = false,
  toolTipContent,
}) => {
  const { locale, defaultLocale } = useRouter();
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang =
    parsedLocale[0] === DEFAULT_LANGUAGE_CODE
      ? DEFAULT_LOCALE?.toLowerCase()
      : parsedLocale[0];

  return heading || subheading ? (
    <div className="mb-2">
      <p lang={lang} className={styles.heading}>
        {heading}
        {withToolTip && toolTipContent && (
          <CardModal
            asIcon
            modalLabel={heading}
            modalContent={toolTipContent}
          />
        )}
      </p>
      {subheading && (
        <p lang={lang} className={styles.subheading}>
          {subheading}
        </p>
      )}
    </div>
  ) : null;
};

export default memo(CardHeading);
