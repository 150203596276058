import { FC, memo, useState, Fragment } from 'react';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import ToggleButton from '@components/ToggleButton';
import Image from '@components/Image';
import { FPS_BUTTON_LABEL } from '@constants';
import type { Fps } from '@framework/api/types';
import clsx from 'clsx';
import { CardHeading } from './CardComponents';

import * as styles from './styles';

type Props = {
  noContentWrapper?: boolean;
  noBackground?: boolean;
  showText?: boolean;
  fps: {
    chipsetType?: string;
    fps: Fps;
  };
  chipsetType?: string;
};

const DATA_MAP = {
  intel: 'gameFpsIntel',
  amd: 'gameFpsAmd',
};

const FpsCard: FC<Props> = ({
  fps,
  chipsetType,
  noContentWrapper,
  noBackground,
  showText = true,
}) => {
  const [selectedFps, setSelectedFps] = useState('1080');
  const ESTI_PERF = t('estimated_fps');
  const handleClick = (val: string): void => {
    if (val !== selectedFps) {
      setSelectedFps(val);
    }

    GTM.dataLayer({
      dataLayer: {
        event: 'toggleFPSPDP',
      },
    });
  };
  const PERFORMANCE = t('performance');
  const FPS_DISCLAIMER = t('fps_disclaimer');

  return fps && Array.isArray(fps?.fps) && fps?.fps.length > 0 ? (
    <div
      className={clsx(
        styles.cardBase(noContentWrapper, noBackground),
        'flex flex-col',
        {
          [styles.fpsWrapper]: !noContentWrapper,
        }
      )}
    >
      {showText && <CardHeading heading={PERFORMANCE} subheading={ESTI_PERF} />}
      <div className={styles.gamesWrapper}>
        {fps?.fps?.map(game => (
          <Fragment key={game.id}>
            {selectedFps === game.fpsSetting &&
              game?.games &&
              Array.isArray(game?.games) &&
              game.games.map((item, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.gameFpsAmd || item.gameFpsIntel}-${i}`}
                  className={styles.game}
                >
                  {item?.gameLogo && (
                    <div className={styles.gameBox}>
                      <Image
                        data={item?.gameLogo.responsiveImage}
                        width={item?.gameLogo.responsiveImage.width}
                        height={item?.gameLogo.responsiveImage.height}
                        loading="eager"
                      />
                    </div>
                  )}
                  {chipsetType && (item.gameFpsAmd || item.gameFpsIntel) && (
                    <div className={styles.fpsTextWrapper}>
                      {item[DATA_MAP[chipsetType]].toLowerCase() ===
                      'pending' ? (
                        <p className={styles.pending}>
                          {item[DATA_MAP[chipsetType]]}
                        </p>
                      ) : (
                        <>
                          <p className={styles.fps}>
                            {item[DATA_MAP[chipsetType]]}
                          </p>
                          <span className={styles.fpsLabel}>FPS</span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </Fragment>
        ))}
      </div>
      <div className={styles.mlAuto}>
        <ToggleButton
          handleClick={handleClick}
          activeItem={selectedFps}
          options={FPS_BUTTON_LABEL}
          isNotCentered
          isPurple
        />
      </div>
      {showText && <div className={styles.fpsDisclaimer}>{FPS_DISCLAIMER}</div>}
    </div>
  ) : null;
};

export default memo(FpsCard);
