import { FC, InputHTMLAttributes, memo } from 'react';
import cn from 'clsx';
import * as styles from './styles';

type ClassProps = {
  toggleButtonWrapper?: string;
  toggleButtonStyles?: string;
};

type OptionsProps = {
  name: string;
  text: string;
};

type OptionsDefaultProps = {
  label: string;
  value: string;
};

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  classes?: ClassProps;
  handleClick: (opt: string) => void;
  options: OptionsProps[] | OptionsDefaultProps[];
  activeItem: string;
  customSpacing?: boolean;
  isPurple?: boolean;
  isNotCentered?: boolean;
  isInverted?: boolean;
}

const ToggleButton: FC<Props> = ({
  classes,
  handleClick,
  activeItem,
  options,
  customSpacing,
  isPurple,
  isNotCentered,
  isInverted,
}) => {
  const rootClassName = cn(
    styles.chipsetButtonWrapper(
      customSpacing,
      isPurple,
      isNotCentered,
      isInverted
    ),
    {},
    classes?.toggleButtonWrapper
  );

  return (
    <div className={rootClassName}>
      {options.map(option => (
        <button
          type="button"
          key={option.value}
          onClick={() => handleClick(option.value)}
          className={cn(
            styles.chipsetButton(
              option.value === activeItem,
              customSpacing,
              isPurple,
              isInverted
            ),
            {},
            classes?.toggleButtonStyles
          )}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default memo(ToggleButton);
