import { FC, memo, useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import useFpsStore, { getFps } from '@stores/use-fps-store';
import ToggleButton from '@components/ToggleButton';
import { FPS_BUTTON_LABEL4K } from '@constants';
import type { UlFps } from '@framework/api/types';
import clsx from 'clsx';
import { CardHeading } from './CardComponents';
import * as styles from './styles';

type Props = {
  noContentWrapper?: boolean;
  overrideFps?: UlFps;
  isDark?: boolean;
  noHeading?: boolean;
};

const GAMES_TO_OBJ_MAP = {
  Starfield: 'starfield',
  'Call of Duty Modern Warfare': 'cod',
  Fortnite: 'fortnite',
  'League of Legends': 'lol',
  'GTA V': 'gtav',
};

const FpsCard: FC<Props> = ({
  noContentWrapper,
  overrideFps,
  isDark = false,
  noHeading = false,
}) => {
  const { asPath } = useRouter();
  const fpsStoreState = useFpsStore(getFps);
  const fpsState = overrideFps || fpsStoreState;
  const [selectedFps, setSelectedFps] = useState('1080');
  const ESTI_PERF = t('estimated_fps');
  const handleClick = (val: string): void => {
    if (val !== selectedFps) {
      setSelectedFps(val);
    }

    GTM.dataLayer({
      dataLayer: {
        event: 'toggleFPSPDP',
      },
    });
  };
  const PERFORMANCE = t('performance');
  const PENDING = t('pending');
  const FPS_DISCLAIMER = t('fps_disclaimer');

  const [pendingText, setPendingText] = useState(PENDING);

  useEffect(() => {
    setPendingText('');

    const timeout = setTimeout(() => {
      setPendingText(PENDING);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [PENDING, asPath]);

  return (
    <div className={clsx({ dark: isDark })}>
      <div
        className={clsx(styles.cardBase(), 'flex flex-col h-full', {
          [styles.fpsWrapper]: !noContentWrapper,
        })}
      >
        {!noHeading && (
          <CardHeading heading={PERFORMANCE} subheading={ESTI_PERF} />
        )}
        <div className={styles.gamesWrapperPrebuild}>
          {Object.keys(GAMES_TO_OBJ_MAP)?.map(game => (
            <Fragment key={`fps-${game}`}>
              <div className={styles.gameBoxPrebuild}>
                <div className={styles.gameNamePrebuild}>{game}</div>
                <div className={styles.fpsTextWrapperPrebuild}>
                  <p className={styles.fpsPrebuild}>
                    {fpsState &&
                    fpsState[GAMES_TO_OBJ_MAP[game]] &&
                    fpsState[GAMES_TO_OBJ_MAP[game]][selectedFps] !== undefined
                      ? fpsState[GAMES_TO_OBJ_MAP[game]][selectedFps]
                      : pendingText}
                  </p>
                  <span className={styles.fpsLabel}>FPS</span>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        <ToggleButton
          handleClick={handleClick}
          activeItem={selectedFps}
          options={FPS_BUTTON_LABEL4K}
        />
        <div className={styles.fpsDisclaimer}>{FPS_DISCLAIMER}</div>
      </div>
    </div>
  );
};

export default memo(FpsCard);
