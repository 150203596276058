import { TArg, classnames } from '@tailwindcss-classnames';

// shared
export const cardBase = (
  noContentWrapper = false,
  noBackground = false
): TArg =>
  classnames(
    {
      'p-4': !noContentWrapper,
      'bg-nzxt-light-grey-50': !noBackground,
    },
    'dark:bg-nzxt-dark-grey-600',
    'rounded-md'
  );

// fps card
export const fpsWrapper = classnames(
  'flex',
  'flex-col',
  'justify-between',
  'h-full',
  cardBase()
);

export const gamesWrapper = classnames(
  'grid',
  'grid-cols-2',
  'w-full',
  'gap-2',
  'md:gap-3',
  'py-3'
);

export const gamesWrapperPrebuild = classnames('pb-3');

export const game = classnames(
  'grid',
  'min-h-[96px]',
  'md:grid-cols-2',
  'bg-white',
  'rounded-md'
);

export const gameBox = classnames(
  'flex',
  'items-center',
  'justify-center',
  'bg-nzxt-light-grey-200',
  'rounded-tr-md',
  'rounded-tl-md',
  'md:rounded-l-md',
  'md:rounded-r-none',
  'py-1',
  'px-2'
);

export const gameBoxPrebuild = classnames(
  'flex',
  'gap-6',
  'items-center',
  'justify-between',
  'py-1',
  'border-b',
  'border-nzxt-light-grey-200',
  'last:border-b-0'
);

export const gameNamePrebuild = classnames(
  'flex-1',
  'text-sm',
  'dark:text-white'
);

export const fpsTextWrapper = classnames(
  'my-auto',
  'text-center',
  'mx-auto',
  'flex',
  'md:block',
  'items-baseline',
  'py-2',
  'md:py-0'
);

export const fpsTextWrapperPrebuild = classnames(
  'my-auto',
  'text-right',
  'mx-auto',
  'flex',
  'flex-col',
  'items-end',
  'py-1'
);

export const fps = classnames(
  'text-2xl',
  'leading-6',
  'font-bold',
  'text-nzxt-green-400',
  'mr-1'
);

export const fpsPrebuild = classnames(
  'font-bold',
  'text-nzxt-green-400',
  'dark:text-nzxt-green-300',
  'leading-none'
);

export const pending = classnames('font-semibold', 'text-sm');
export const mlAuto = classnames('mx-auto', 'md:ml-auto', 'md:mr-0');

// spec card
export const specWrapper = classnames(
  'grid',
  'grid-rows-4',
  'md:gap-4',
  'gap-0',
  'grid-flow-col'
);

export const headingSection = classnames('row-span-1');
export const detailsSection = classnames('row-span-2', 'flex', 'flex-col');
export const detailslist = classnames('list-disc', 'pl-6', 'space-y-2');
export const hyphensAuto = classnames('hyphens-auto');
export const fpsLabel = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200',
  'text-xs'
);

export const fpsDisclaimer = classnames(
  'text-xs',
  'text-center',
  'mt-4',
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-200'
);
