import { FC, memo, Fragment } from 'react';
import { useTranslation as t } from '@utils/hooks';

import * as styles from './styles';

type Props = {
  rating: string;
};

const CardScale: FC<Props> = ({ rating }) => {
  const GOOD_LABEL = t('good_rating_label');
  const GOOD_PLUS_LABEL = '';
  const BETTER_LABEL = t('better_rating_label');
  const BETTER_PLUS_LABEL = '';
  const BEST_LABEL = t('best_rating_label');

  const MAP_TO_VALUE = {
    good: 1,
    good_plus: 1.5,
    better: 2,
    better_plus: 2.5,
    best: 3,
  };

  const RATING_MAP = [
    { label: GOOD_LABEL, value: 1 },
    { label: GOOD_PLUS_LABEL, value: 1.5 },
    { label: BETTER_LABEL, value: 2 },
    { label: BETTER_PLUS_LABEL, value: 2.5 },
    { label: BEST_LABEL, value: 3 },
  ];

  return rating ? (
    <div className={styles.scaleItemWrapper}>
      {RATING_MAP.map(rat => (
        <div key={rat.value} className={styles.arrowWrapper}>
          <div
            className={styles.downArrow(
              rat.value ===
                MAP_TO_VALUE[rating.replace(/ /g, '_').toLowerCase()]
            )}
            style={{
              borderLeft: '9px solid transparent',
              borderRight: '9px solid transparent',
              borderTop: '16px solid #8A00FC',
            }}
          />
        </div>
      ))}
      <div className={styles.scale} />
      {RATING_MAP.map(rat => (
        <Fragment key={rat.value}>
          <p
            className={styles.scaleItem(
              rat.value === MAP_TO_VALUE[rating.toLowerCase()]
            )}
          >
            {rat.label}
          </p>
        </Fragment>
      ))}
    </div>
  ) : null;
};

export default memo(CardScale);
