import { classnames } from '@tailwindcss-classnames';

const chipsetActive = classnames(
  'border',
  'bg-white',
  'border-gray-300',
  'shadow-lg',
  'dark:bg-black',
  'pointer-events-none',
  'dark:text-white'
);

const chipsetActivePurple = classnames(
  'border',
  'bg-nzxt-volt-400',
  'border-gray-300',
  'shadow-lg',
  'text-white'
);

const chipsetButtonDefaultSpacing = classnames('px-4');
const chipsetDefaultText = classnames(
  'text-gray-500',
  'dark:text-nzxt-dark-grey-50'
);
const chipsetDefault = classnames(
  'hover:bg-white',
  'border',
  'border-transparent'
);
const chipsetPurple = classnames('hover:bg-nzxt-volt-500', 'hover:text-white');
const chipsetActiveDark = classnames(
  'text-white',
  'bg-nzxt-light-grey-800',
  'hover:bg-nzxt-light-grey-600'
);
const chipsetDark = classnames(
  'text-nzxt-dark-grey-300',
  'hover:bg-nzxt-light-grey-600'
);

export const chipsetButton = (
  isActive: boolean,
  customSpacing: boolean,
  isPurple: boolean,
  isInverted?: boolean
): string =>
  classnames(
    'relative',
    'block',
    'items-center',
    'py-1',
    'rounded-md',
    'text-sm',
    'font-medium',
    'hover:bg-opacity-50',
    'transition-colors',
    'duration-75',
    {
      [chipsetButtonDefaultSpacing]: !customSpacing,
      [chipsetDefaultText]: !isActive && !isInverted,
      [chipsetDefault]: !isPurple && !isInverted,
      [chipsetActive]: isActive && !isPurple && !isInverted,
      [chipsetActivePurple]: isActive && isPurple,
      [chipsetPurple]: isPurple,
      [chipsetActiveDark]: isActive && isInverted,
      [chipsetDark]: !isActive && isInverted,
    }
  );

const chipsetDefaultSpacing = classnames('gap-2');

const isDefaultButtons = classnames(
  'bg-nzxt-light-grey-200',
  'dark:bg-nzxt-dark-grey-700'
);
const isDarkButtons = classnames('bg-nzxt-dark-grey-900');
const isPurpleButtons = classnames('bg-white');
const isCentered = classnames('mx-auto');

export const chipsetButtonWrapper = (
  customSpacing: boolean,
  isPurple: boolean,
  isNotCentered: boolean,
  isInverted?: boolean
): string =>
  classnames(
    'relative',
    'z-0',
    'inline-flex',
    'rounded-md',
    'p-1',
    {
      [chipsetDefaultSpacing]: !customSpacing,
      [isDefaultButtons]: !isPurple && !isInverted,
      [isPurpleButtons]: isPurple,
      [isCentered]: !isNotCentered,
      [isDarkButtons]: isInverted,
    },
    'max-w-max'
  );
