import { FC, memo, useState } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import GTM from '@utils/gtm';
import { IconCircleInfo } from '@nzxt/react-icons';
import Modal from '@components/Modal';
import HydrateMdx from '@components/HydrateMdx';
import Button from '@components/Button';

import * as styles from './styles';

type Props = {
  modalLabel?: string;
  modalContent?: string;
  modalMarkdown?: MDXRemoteSerializeResult;
  asIcon?: boolean;
};

const CardModal: FC<Props> = ({
  modalLabel,
  modalContent,
  modalMarkdown,
  asIcon = false,
}) => {
  const hasMarkdown = Boolean(modalMarkdown);
  const [showDialog, setShowDialog] = useState(false);
  const open = (): void => {
    setShowDialog(true);
    GTM.dataLayer({
      dataLayer: {
        event: 'keySpecsModal',
      },
    });
  };
  const close = (): void => setShowDialog(false);

  return modalLabel ? (
    <div className={styles.modalWrapper(asIcon)}>
      <Button
        onClick={() => open()}
        buttonStyle="textButton"
        customMargin
        className={styles.modalButton(asIcon)}
        aria-label={modalLabel}
      >
        {asIcon ? (
          <IconCircleInfo
            className={styles.chipHelpSvg}
            aria-label={modalLabel}
          />
        ) : (
          modalLabel
        )}
      </Button>
      <Modal open={showDialog} onClose={close} ariaLabel={() => modalLabel}>
        <div className="prose">
          {hasMarkdown ? (
            <HydrateMdx mdx={modalMarkdown} />
          ) : (
            <p>{modalContent}</p>
          )}
        </div>
      </Modal>
    </div>
  ) : null;
};

export default memo(CardModal);
