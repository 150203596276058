import { classnames } from '@tailwindcss-classnames';

// heading
export const heading = classnames(
  'text-lg',
  'hyphens-auto',
  'font-bold',
  'flex',
  'dark:text-white'
);
export const subheading = classnames(
  'text-sm',
  'hyphens-auto',
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-200'
);

// scale
export const arrowWrapper = classnames('mx-auto');
const activeItem = classnames('font-bold');
const inactiveItem = classnames(
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-200'
);
export const scaleItem = (isActive: boolean): string =>
  classnames('text-sm', {
    [activeItem]: isActive,
    [inactiveItem]: !isActive,
  });
export const scaleItemWrapper = classnames(
  'grid',
  'grid-cols-card-scale',
  'justify-center',
  'items-center',
  'text-center',
  'mb-4'
);

const inactiveArrow = classnames('opacity-0');
export const downArrow = (isActive: boolean): string =>
  classnames('w-0', 'h-0', {
    [inactiveArrow]: !isActive,
  });

export const scale = classnames(
  'h-1',
  'rounded-md',
  'col-span-5',
  'bg-nzxt-light-grey-300',
  'my-2'
);

// modal
const marginTop = classnames('mt-auto');
export const modalWrapper = (asIcon?: boolean): string =>
  classnames('flex', {
    [marginTop]: !asIcon,
  });

export const spacing = classnames('mt-4');
export const iconStyles = classnames('!h-auto', 'ml-1');
export const modalButton = (asIcon?: boolean): string =>
  classnames({ [spacing]: !asIcon, [iconStyles]: asIcon });
export const chipHelpSvg = classnames('h-5', 'w-5', 'text-blue-600');
