import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import parseLocale from '@utils/parse-locale';
import { DEFAULT_LOCALE, DEFAULT_LANGUAGE_CODE } from '@constants';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import clsx from 'clsx';
import { CardHeading, CardScale, CardModal } from './CardComponents';

import * as styles from './styles';

type DetailsProps = {
  detail: string;
};

type Props = {
  heading: string;
  subheading: string;
  rating: string;
  details: DetailsProps[];
  modalLabel?: string;
  modalContent?: string;
  keySpecsCardModalMarkdown?: MDXRemoteSerializeResult;
  noBackground?: boolean;
};

const SpecsCard: FC<Props> = ({
  heading,
  subheading,
  rating,
  details,
  modalLabel,
  modalContent,
  keySpecsCardModalMarkdown,
  noBackground,
}) => {
  const { locale, defaultLocale } = useRouter();
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang =
    parsedLocale[0] === DEFAULT_LANGUAGE_CODE
      ? DEFAULT_LOCALE?.toLowerCase()
      : parsedLocale[0];

  return (
    <div
      className={clsx(
        styles.specWrapper,
        styles.cardBase(noBackground, noBackground)
      )}
    >
      <div className={styles.headingSection}>
        <CardHeading heading={heading} subheading={subheading} />
      </div>
      <div className={styles.headingSection}>
        <CardScale rating={rating} />
      </div>
      <div className={styles.detailsSection}>
        <ul className={styles.detailslist}>
          {details &&
            Array.isArray(details) &&
            details?.length > 0 &&
            details?.map(
              item =>
                item?.detail && (
                  <li
                    lang={lang}
                    key={item.detail}
                    className={styles.hyphensAuto}
                  >
                    {item.detail}
                  </li>
                )
            )}
        </ul>
        <CardModal
          modalLabel={modalLabel}
          modalMarkdown={keySpecsCardModalMarkdown}
          modalContent={modalContent}
        />
      </div>
    </div>
  );
};

export default memo(SpecsCard);
